import { css, StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";

export type TbodyProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  tbody: {
    border: "0"
  }
});

export const Tbody: FC<TbodyProps> = ({ children }) => {
  return <tbody className={css(styles.tbody)}>{children}</tbody>;
};
