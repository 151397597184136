import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { useFormContext } from "react-hook-form/dist/index.ie11";
import { Layout, Text } from "~/components";
import { fontSizes, spacing, colors } from "~/styles";

export type InputProps = {
  id: string;
  name: string;
  type?: "email" | "password" | "text";
  labelText?: string;
  autoComplete?: string;
  autoCorrect?: string;
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.small,
    marginBottom: spacing.small,
    ":first-child": {
      marginTop: "0"
    }
  },
  label: {
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack,
    display: "block",
    marginTop: "0",
    marginBottom: spacing.xxsmall
  },
  input: {
    background: "transparent",
    border: "0px",
    outline: "none",
    borderBottomWidth: "2px",
    borderBottomStyle: "dashed",
    borderBottomColor: colors.shadowDark,
    borderRadius: "0px",
    fontSize: "16px",
    paddingTop: spacing.xxsmall,
    paddingBottom: spacing.xxsmall,
    paddingLeft: "0px",
    paddingRight: "0px",
    resize: "vertical",
    display: "block",
    width: "100%",
    appearance: "none",
    ":focus": {
      outline: "none",
      borderBottomColor: colors.cobalt
    },
    "::placeholder": {
      color: colors.gray
    },
    ":focus::placeholder": {
      color: colors.shadowDark
    }
  },
  error: {
    fontSize: fontSizes.text.xsmall,
    margin: "0",
    color: colors.red
  }
});

export const Input: VFC<InputProps> = ({
  id,
  name,
  type = "text",
  labelText,
  autoComplete,
  autoCorrect
}) => {
  const { register, errors } = useFormContext();
  return (
    <Layout element="div" styles={styles.container}>
      {errors?.email?.message && (
        <Text element="div" styles={styles.error}>
          {errors?.email.message}
        </Text>
      )}
      {labelText && (
        <label htmlFor={id} className={css(styles.label)}>
          {labelText}
        </label>
      )}
      <input
        type={type}
        id={id}
        name={name}
        ref={register}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        className={css(styles.input)}
      />
    </Layout>
  );
};
