import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Text } from "~/components";
import { borderRadius, colors, fontSizes, spacing } from "~/styles";

export type ErrorboxProps = {
  text: string;
};

const styles = StyleSheet.create({
  errorbox: {
    fontSize: fontSizes.text.xsmall,
    fontWeight: 400,
    color: colors.red,
    padding: spacing.xsmall,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: colors.red,
    borderRadius: borderRadius.small
  }
});

export const Errorbox: VFC<ErrorboxProps> = ({ text }) => {
  return (
    <Text element="div" styles={styles.errorbox}>
      {text}
    </Text>
  );
};
