import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Greeting, Images, Profile, Story } from "./components";
import { Layout } from "~/components";

export type AboutProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  about: {}
});

export const About: FC<AboutProps> & {
  Story: typeof Story;
  Profile: typeof Profile;
  Greeting: typeof Greeting;
  Images: typeof Images;
} = ({ children }) => {
  return (
    <Layout element="div" styles={styles.about}>
      {children}
    </Layout>
  );
};

About.Story = Story;
About.Profile = Profile;
About.Greeting = Greeting;
About.Images = Images;
