import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { colors, spacing } from "~/styles";

export type RowProps = {
  head: string;
  body: string;
};

const styles = StyleSheet.create({
  row: {
    border: "0"
  },
  heading: {
    whiteSpace: "nowrap",
    borderBottomWidth: "1px",
    borderBottomStyle: "dashed",
    borderBottomColor: colors.gray,
    padding: spacing.xxsmall,
    verticalAlign: "baseline"
  },
  data: {
    whiteSpace: "pre-wrap",
    borderBottomWidth: "1px",
    borderBottomStyle: "dashed",
    borderBottomColor: colors.gray,
    paddingTop: spacing.xsmall,
    paddingLeft: spacing.xxsmall,
    paddingRight: spacing.xxsmall,
    paddingBottom: spacing.xsmall,
    verticalAlign: "baseline"
  }
});

const Heading: VFC<{
  text: string;
}> = ({ text }) => {
  return <th className={css(styles.heading)}>{text}</th>;
};

const Data: VFC<{
  text: string;
}> = ({ text }) => {
  return <td className={css(styles.data)}>{text}</td>;
};

export const Row: VFC<RowProps> = ({ head, body }) => {
  return (
    <tr className={css(styles.row)}>
      <Heading text={head} />
      <Data text={body} />
    </tr>
  );
};
