import { useEffect, useMemo, useState, VFC } from "react";
import { createPortal } from "react-dom";
import { nanoid } from "nanoid";
import { css, StyleSheet } from "aphrodite";
import { useImagePortal } from "../Images";
import { Button, Layout } from "~/components";
import { spacing, zIndex } from "~/styles";
import { useBodyFix } from "~/hooks";

export const Portal: VFC = () => {
  const { source, handleClose, isOpen } = useImagePortal();
  const { toFix, toFlow } = useBodyFix();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      toFix();
      const timer = window.setTimeout(() => {
        setIsActive(true);
        window.clearTimeout(timer);
      }, 0);
    }
  }, [isOpen]);

  const styles = StyleSheet.create({
    portal: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      paddingLeft: spacing.large,
      paddingRight: spacing.large,
      paddingTop: spacing.large,
      paddingBottom: spacing.large,
      zIndex: zIndex.foreground,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    inner: {
      width: "100%",
      maxWidth: "600px",
      top: "25%",
      transform: isActive ? "scale(1)" : "scale(0)",
      transformOrigin: "center center",
      transition: "transform 650ms ease"
    },
    image: {
      maxWidth: "100%"
    }
  });

  const id = useMemo(() => `portal-${nanoid()}`, []);
  const element = (
    <Layout id={id} styles={styles.portal} element="div">
      <Button
        type="button"
        onClick={() => {
          if (isActive) {
            toFlow();
            setIsActive(false);
            const timer = window.setTimeout(() => {
              handleClose();
              window.clearTimeout(timer);
            }, 650);
          }
        }}
        styles={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          background: isActive ? "rgba(0,0,0,0.64)" : "transparent",
          margin: "0",
          padding: "0",
          border: "0",
          width: "100%",
          height: "100%",
          ":hover": {
            background: isActive ? "rgba(0,0,0,0.64)" : "transparent"
          },
          ":active": {
            transform: "none"
          }
        }}
      />
      <Layout element="div" styles={styles.inner}>
        {source && <img src={source} alt="" className={css(styles.image)} />}
      </Layout>
      <div />
    </Layout>
  );
  return createPortal(element, document.body);
};
