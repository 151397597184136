import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { useImagePortal } from "../Images";
import { Button, Layout } from "~/components";
import { fontSizes, media, shadow, spacing } from "~/styles";

export type ImageProps = {
  path: string;
  caption: string;
};

const styles = StyleSheet.create({
  item: {
    marginTop: "0",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: spacing.small,
    [media.medium]: {
      width: `calc(100% / 3 - ${spacing.xsmall})`,
      ":nth-child(3n + 1)": {
        marginLeft: "0"
      }
    },
    [media.large]: {
      width: `calc(100% / 4 - (${spacing.small} / 4 * 3))`,
      ":nth-child(3n+1)": {
        marginLeft: spacing.small
      },
      ":nth-child(4n+1)": {
        marginLeft: "0"
      }
    }
  },
  figure: {
    display: "block",
    width: "16em",
    [media.medium]: {
      width: "100%"
    }
  },
  figureImage: {
    display: "block",
    width: "100%",
    boxShadow: shadow.xlarge
  },
  figcaption: {
    display: "block",
    fontSize: fontSizes.text.small
  }
});

export const Image: VFC<ImageProps> = ({ path, caption }) => {
  const { handleOpen } = useImagePortal();
  return (
    <Layout element="div" styles={styles.item}>
      <Button
        type="button"
        styles={{
          margin: "0",
          padding: "0",
          border: "0",
          ":hover": {
            background: "transparent"
          }
        }}
        onClick={() => {
          handleOpen(path);
        }}
      >
        <figure className={css(styles.figure)}>
          <img src={path} alt={caption} className={css(styles.figureImage)} />
          <figcaption className={css(styles.figcaption)}>{caption}</figcaption>
        </figure>
      </Button>
    </Layout>
  );
};
