import { StyleSheet } from "aphrodite";
import { FC } from "react";
import { Layout, LinkButton } from "~/components";
import { spacing } from "~/styles";

export type MoreLinkProps = {
  href: string;
  external?: boolean;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: spacing.medium
  }
});

export const MoreLink: FC<MoreLinkProps> = ({ href, external }) => {
  return (
    <Layout element="div" styles={styles.container}>
      <LinkButton element="a" href={href} external={external}>
        MORE
      </LinkButton>
    </Layout>
  );
};
