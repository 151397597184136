import { createContext, useContext, useState, VFC } from "react";
import { StyleSheet } from "aphrodite";
import { SectionTitle } from "../SectionTitle";
import { Image, Portal } from "./components";
import { Layout, Section, Text } from "~/components";
import { useTranslation } from "~/hooks";
import { colors, fontSizes, media, spacing } from "~/styles";

const styles = StyleSheet.create({
  description: {
    marginTop: spacing.xsmall,
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack
  },
  imagesList: {
    marginLeft: `-${spacing.small}`,
    marginRight: `-${spacing.small}`,
    marginTop: "0",
    marginBottom: "0",
    paddingLeft: spacing.small,
    paddingRight: spacing.small,
    paddingTop: spacing.medium,
    paddingBottom: spacing.medium,
    scrollSnapType: "x mandatory",
    scrollPadding: `0px ${spacing.small}`,
    overflowX: "auto",
    overflowY: "hidden",
    flexDirection: "row",
    flexWrap: "nowrap",
    ":nth-child(n) > *": {
      scrollSnapAlign: "start"
    },
    [media.medium]: {
      maxWidth: "100%",
      flexWrap: "wrap"
    }
  }
});

type ImageItem = {
  path: string;
  caption: string;
};

type ImagePortalContextState = {
  isOpen: boolean;
  source: string | null;
  handleOpen: (src: string) => void;
  handleClose: () => void;
};

export const ImagePortalContext = createContext<ImagePortalContextState>({
  isOpen: false,
  source: null,
  handleOpen: () => {},
  handleClose: () => {}
});

export const useImagePortal = () => useContext(ImagePortalContext);

export const Images: VFC = () => {
  const { dic } = useTranslation();
  const imageList: ImageItem[] = [
    {
      path: "/images/about/manufacturing_honsya.jpg",
      caption: dic.about.imageCaptinos.honsya
    },
    {
      path: "/images/about/manufacturing_cut.jpg",
      caption: dic.about.imageCaptinos.cut
    },
    {
      path: "/images/about/manufacturing_kinzoku.jpg",
      caption: dic.about.imageCaptinos.kinzoku
    },
    {
      path: "/images/about/manufacturing_syokuniku.jpg",
      caption: dic.about.imageCaptinos.syokuniku
    },
    {
      path: "/images/about/manufacturing_mooring.jpg",
      caption: dic.about.imageCaptinos.mooring
    },
    {
      path: "/images/about/manufacturing_tochiku.jpg",
      caption: dic.about.imageCaptinos.tochiku
    },
    {
      path: "/images/about/manufacturing_unloading.jpg",
      caption: dic.about.imageCaptinos.unloading
    },
    {
      path: "/images/about/manufacturing_housou.jpg",
      caption: dic.about.imageCaptinos.housou
    }
  ];

  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const [popupSource, setPopupSource] = useState<string | null>(null);

  const openPopupImage = (src: string): void => {
    setPopupSource(src);
    setPopupIsOpen(true);
  };

  const closePopupImage = (): void => {
    setPopupSource(null);
    setPopupIsOpen(false);
  };
  return (
    <Section withSpacing>
      <SectionTitle headingText={dic.about.images.title} />
      <Text element="p" styles={styles.description}>
        {dic.about.images.description}
      </Text>
      <Layout element="div" styles={styles.imagesList}>
        <ImagePortalContext.Provider
          value={{
            isOpen: popupIsOpen,
            source: popupSource,
            handleOpen: openPopupImage,
            handleClose: closePopupImage
          }}
        >
          {imageList.map((item, i) => (
            <Image
              key={`${item.caption}[${i}]`}
              path={item.path}
              caption={item.caption}
            />
          ))}
          <div
            id="spacer"
            style={{
              minWidth: "0.75em"
            }}
          />
          {popupIsOpen && <Portal />}
        </ImagePortalContext.Provider>
      </Layout>
    </Section>
  );
};
