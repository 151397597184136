import { VFC } from "react";
import { css, StyleSheet } from "aphrodite";
import { SectionTitle } from "../SectionTitle";
import { InlineText, Layout, Section, Text } from "~/components";
import { useTranslation } from "~/hooks";
import { colors, fontSizes, leading, media, shadow, spacing } from "~/styles";

const styles = StyleSheet.create({
  container: {
    [media.medium]: {
      flexDirection: "row"
    }
  },
  imageContainer: {
    width: "100%",
    maxWidth: "100%",
    flex: "1 1 auto",
    [media.medium]: {
      flex: "0 0 auto",
      maxWidth: "16em"
    }
  },
  imageWrapper: {
    width: "100%",
    maxWidth: "16em",
    margin: "0 auto",
    boxShadow: shadow.xlarge,
    [media.medium]: {
      paddingTop: "100%",
      borderRadius: "50%",
      overflow: "hidden"
    }
  },
  image: {
    display: "block",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    objectFit: "cover",
    [media.medium]: {
      position: "absolute",
      top: "0",
      left: "0"
    }
  },
  paragraphContainer: {
    flex: "1 1 auto",
    width: "100%",
    maxWidth: "46rem",
    marginTop: spacing.medium,
    [media.medium]: {
      marginTop: "0",
      marginLeft: spacing.medium
    }
  },
  paragraph: {
    marginBottom: "0",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack,
    lineHeight: leading.loose
  },
  sign: {
    textAlign: "right"
  }
});

export const Greeting: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Section withSpacing>
      <SectionTitle headingText={dic.about.greeting.title} />
      <Layout element="div" styles={styles.container}>
        <Layout element="div" styles={styles.imageContainer}>
          <Layout element="div" styles={styles.imageWrapper}>
            <img
              src="/images/about/chairman.jpg"
              alt="chairman"
              className={css(styles.image)}
            />
          </Layout>
        </Layout>
        <Layout element="div" styles={styles.paragraphContainer}>
          <Text element="p" styles={styles.paragraph}>
            {dic.about.greeting.body}
          </Text>
          <Text element="p" styles={styles.sign}>
            <InlineText element="em">
              {dic.about.profile.ceo.head}
              &nbsp;
              {dic.about.profile.ceo.body}
            </InlineText>
          </Text>
        </Layout>
      </Layout>
    </Section>
  );
};
