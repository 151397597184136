import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { useFormContext } from "react-hook-form/dist/index.ie11";
import { Layout, Text } from "~/components";
import { colors, fontSizes, spacing } from "~/styles";

export type TextareaProps = {
  id: string;
  name: string;
  labelText?: string;
  rows?: number;
  maxLength?: number;
  placeholder?: string;
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.small,
    marginBottom: spacing.small,
    ":first-child": {
      marginTop: "0"
    }
  },
  label: {
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack,
    display: "block",
    marginTop: "0",
    marginBottom: spacing.xxsmall
  },
  input: {
    background: "transparent",
    border: "0px",
    outline: "none",
    borderBottomWidth: "2px",
    borderBottomStyle: "dashed",
    borderBottomColor: colors.shadowDark,
    borderRadius: "0px",
    fontSize: "16px",
    paddingTop: spacing.xxsmall,
    paddingBottom: spacing.xxsmall,
    paddingLeft: "0px",
    paddingRight: "0px",
    resize: "vertical",
    display: "block",
    width: "100%",
    appearance: "none",
    ":focus": {
      outline: "none",
      borderBottomColor: colors.cobalt
    },
    "::placeholder": {
      color: colors.gray
    },
    ":focus::placeholder": {
      color: colors.shadowDark
    }
  },
  error: {
    fontSize: fontSizes.text.xsmall,
    margin: "0",
    color: colors.red
  }
});

export const Textarea: VFC<TextareaProps> = ({
  id,
  name,
  labelText,
  rows,
  maxLength,
  placeholder
}) => {
  const { register, errors } = useFormContext();
  return (
    <Layout element="div" styles={styles.container}>
      {errors?.body?.message && (
        <Text element="div" styles={styles.error}>
          {errors?.body.message}
        </Text>
      )}
      {labelText && (
        <label htmlFor={id} className={css(styles.label)}>
          {labelText}
        </label>
      )}
      <textarea
        className={css(styles.input)}
        name={name}
        id={id}
        ref={register}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </Layout>
  );
};
