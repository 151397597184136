import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Details, Image, Section } from "./components";
import { Layout } from "~/components";

export type ArticleProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  article: {}
});

export const Article: FC<ArticleProps> & {
  Image: typeof Image;
  Section: typeof Section;
  Details: typeof Details;
} = ({ children }) => {
  return (
    <Layout element="article" styles={styles.article}>
      {children}
    </Layout>
  );
};

Article.Image = Image;
Article.Section = Section;
Article.Details = Details;
