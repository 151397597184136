import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Icon, InlineText, Layout, Link } from "~/components";
import { colors, spacing, variables } from "~/styles";

export type PageControllerProps = {
  category: "news" | "blog";
  hasPrev: boolean;
  hasNext: boolean;
  page: number;
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.large,
    alignItems: "center"
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: spacing.xxsmall,
    marginRight: spacing.xxsmall,
    background: colors.salmon,
    paddingTop: spacing.xxsmall,
    paddingBottom: spacing.xxsmall,
    paddingLeft: spacing.xsmall,
    paddingRight: spacing.xsmall,
    textDecoration: "none",
    outline: "none",
    transition: "opacity 200ms ease,transform 200ms ease,box-shadow 200ms ease",
    ":hover": {
      outline: "none",
      opacity: 0.64
    },
    ":focus": {
      outline: "none"
    },
    ":active": {
      transform: "scale(0.96)"
    },
    ":focus-visible": {
      boxShadow: variables.focusRing
    }
  },
  icon: {
    display: "block",
    width: "1rem",
    height: "1rem",
    color: colors.stoneTint
  },
  text: {
    color: colors.stoneTint
  }
});

export const PageController: VFC<PageControllerProps> = ({
  hasPrev,
  hasNext,
  category,
  page
}) => {
  return hasPrev || hasNext ? (
    <Layout element="div" styles={styles.container}>
      {hasPrev && (
        <Link href={`/${category}/${page - 1}`} styles={styles.link}>
          <Icon source={faAngleLeft} styles={styles.icon} />
          <div
            id="spacer"
            style={{
              minWidth: spacing.xxsmall
            }}
          />
          <InlineText element="span" styles={styles.text}>
            前のページ
          </InlineText>
        </Link>
      )}
      {hasNext && (
        <Link href={`/${category}/${page + 1}`} styles={styles.link}>
          <InlineText element="span" styles={styles.text}>
            次のページ
          </InlineText>
          <div
            id="spacer"
            style={{
              minWidth: spacing.xxsmall
            }}
          />
          <Icon source={faAngleRight} styles={styles.icon} />
        </Link>
      )}
    </Layout>
  ) : null;
};
