import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Blog, News, PageController } from "./components";
import { Layout } from "~/components";

export type ArticleListProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  articleList: {}
});

export const ArticleList: FC<ArticleListProps> & {
  PageController: typeof PageController;
  News: typeof News;
  Blog: typeof Blog;
} = ({ children }) => {
  return (
    <Layout element="div" styles={styles.articleList}>
      {children}
    </Layout>
  );
};

ArticleList.PageController = PageController;
ArticleList.News = News;
ArticleList.Blog = Blog;
