import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import Image from "next/image";
import { VFC } from "react";
import { Heading, Text } from "~/components";
import { Icon } from "~/components/Icon";
import { Layout } from "~/components/Layout";
import { useTranslation } from "~/hooks/useTranslation";
import {
  colors,
  fontFamily,
  fontSizes,
  media,
  spacing,
  tracking
} from "~/styles";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingLeft: spacing.small,
    paddingRight: spacing.small,
    paddingBottom: spacing.xlarge,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [media.medium]: {
      paddingLeft: "18rem",
      paddingBottom: "0px"
    }
  },
  layer: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    zIndex: -1,
    backgroundImage: "url(/images/main_layer.jpg)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    ":before": {
      content: "''",
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to bottom,rgba(255,255,255,0) 52.6%,rgba(255,255,255,1))",
      [media.small]: {
        background:
          "linear-gradient(to bottom,rgba(255,255,255,0) 72.6%,rgba(255,255,255,1))"
      }
    },
    ":after": {
      content: "''",
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.24)",
      zIndex: -1
    }
  },
  inner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingTop: spacing.xxlarge,
    paddingBottom: spacing.xxlarge,
    transform: "translateY(30vh)",
    [media.medium]: {
      transform: "none",
      justifyContent: "center",
      paddingTop: "18em",
      paddingBottom: "4em"
    }
  },
  imageWrapper: {
    width: "100%",
    maxWidth: "8rem",
    marginBottom: spacing.small,
    [media.medium]: {
      maxWidth: "11.2rem"
    }
  },
  title: {
    fontFamily: fontFamily.serif,
    fontWeight: 700,
    fontSize: fontSizes.text.xxlarge,
    letterSpacing: tracking.wide,
    color: "#fff",
    textShadow: "0px 2px 7px rgba(0,0,0,0.64)",
    textAlign: "left",
    marginBottom: spacing.large,
    [media.medium]: {
      fontSize: fontSizes.title.medium
    }
  },
  sign: {
    flexDirection: "row",
    alignItems: "center"
  },
  signText: {
    display: "inline-block",
    margin: "0",
    color: colors.white,
    fontSize: fontSizes.text.xlarge,
    fontWeight: 400,
    fontFamily: fontFamily.serif,
    textShadow: "0px 2px 7px rgba(0,0,0,0.64)"
  },
  signIcon: {
    color: colors.white,
    display: "block",
    width: "1rem",
    height: "1rem",
    marginLeft: spacing.xxsmall,
    animationName: "hop",
    animationTimingFunction: "ease",
    animationIterationCount: "infinite",
    animationDuration: "2s",
    filter: "drop-shadow(0px 2px 7px rgba(0,0,0,0.64))"
  }
});

export const Hero: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Layout element="div" styles={styles.container}>
      <Layout element="div" styles={styles.layer} />
      <Layout element="div" styles={styles.inner}>
        <Layout element="div" styles={styles.imageWrapper}>
          <Image
            src="/images/logo_white_nakid.svg"
            width={250}
            height={480}
            layout="responsive"
          />
        </Layout>
        <Heading element="h1" styles={styles.title}>
          {dic.catchphrase}
        </Heading>
        <Layout element="div" styles={styles.sign}>
          <Text element="span" styles={styles.signText}>
            SCROLL
          </Text>
          <Icon source={faAngleDoubleDown} styles={styles.signIcon} />
        </Layout>
      </Layout>
    </Layout>
  );
};
