import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import dayjs from "dayjs";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Icon, Layout, Link as LinkComponent } from "~/components";
import { News } from "~/lib/server/microcms";
import { colors, fontSizes, media, spacing } from "~/styles";

export type LinkProps = {
  item: News;
};

const styles = StyleSheet.create({
  link: {
    display: "flex",
    flexDirection: "column",
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.xsmall,
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    transitionProperty: "opacity, box-shadow",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease",
    width: "100%",
    [media.medium]: {
      flexDirection: "row"
    }
  },
  date: {
    color: colors.gray
  },
  details: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing.xsmall,
    [media.medium]: {
      marginTop: "0",
      marginLeft: spacing.xsmall,
      justifyContent: "flex-end",
      flex: "1 1 auto"
    }
  },
  title: {
    flex: "1 1 auto"
  },
  icon: {
    width: "1em",
    height: "1em",
    marginLeft: spacing.xxsmall,
    marginRight: spacing.xxsmall
  }
});

export const Link: VFC<LinkProps> = ({ item }) => {
  return (
    <LinkComponent
      href={`/news/article/${item.id}`}
      label={item.title}
      styles={styles.link}
    >
      <Layout element="div" styles={styles.date}>
        <span>{dayjs(item.publishedAt).format("YYYY年M月D日")}</span>
      </Layout>
      <Layout element="div" styles={styles.details}>
        <Layout element="div" styles={styles.title}>
          {item.title}
        </Layout>
        <Icon source={faAngleRight} styles={styles.icon} />
      </Layout>
    </LinkComponent>
  );
};
