import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Heading, Layout, Section, Text } from "~/components";
import { useTranslation } from "~/hooks";
import { spacing } from "~/styles";

const styles = StyleSheet.create({
  textBox: {
    display: "block"
  },
  heading: {
    marginTop: spacing.xsmall,
    marginBottom: spacing.xxsmall,
    ":first-child": {
      marginTop: "0"
    }
  },
  text: {
    marginTop: spacing.xxsmall,
    marginBottom: spacing.xxsmall
  },
  frameBox: {
    marginTop: spacing.large
  }
});

export const Info: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Section withSpacing>
      <Layout styles={styles.textBox}>
        <Heading element="h2" styles={styles.heading}>
          {dic.contact.koudoInfo.name}
        </Heading>
        <Text element="p" styles={styles.text}>
          {dic.contact.koudoInfo.address}
        </Text>
        <Text element="p" styles={styles.text}>
          <a href="tel:080-4099-2699">080-4099-2699</a>
        </Text>
        <Text element="p" styles={styles.text}>
          {dic.contact.koudoInfo.time}
        </Text>
        <Text element="p" styles={styles.text}>
          {dic.contact.koudoInfo.closed}
        </Text>
        <Heading element="h2" styles={styles.heading}>
          {dic.about.profile.name.body}
        </Heading>
        <Text element="p" styles={styles.text}>
          {dic.about.profile.address.body}
        </Text>
      </Layout>
      <Layout styles={styles.frameBox}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50374.56878219291!2d139.01479419502422!3d37.8974655435166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff4c63f1f9c2f59%3A0x7f2ff69fa78f78c7!2z44CSOTUwLTIwMzIg5paw5r2f55yM5paw5r2f5biC6KW_5Yy655qE5aC05rWB6YCa77yR5LiB55uu77yT4oiS77yR77yRIOOCiOOBreS4gA!5e0!3m2!1sja!2sjp!4v1623674072095!5m2!1sja!2sjp"
          width="100%"
          height="450"
          style={{
            border: "0"
          }}
          allowFullScreen={false}
          loading="lazy"
        ></iframe>
      </Layout>
    </Section>
  );
};
