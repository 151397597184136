import { VFC } from "react";
import { SectionTitle } from "../SectionTitle";
import { Table } from "./components";
import { Section } from "~/components";
import { useTranslation } from "~/hooks";
import { colors } from "~/styles";

export const Profile: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Section
      withSpacing
      styles={{
        backgroundColor: colors.stoneTint
      }}
    >
      <SectionTitle headingText={dic.about.profile.title} />
      <Table>
        <Table.Tbody>
          <Table.Row
            head={dic.about.profile.name.head}
            body={dic.about.profile.name.body}
          />
          <Table.Row
            head={dic.about.profile.ceo.head}
            body={dic.about.profile.ceo.body}
          />
          <Table.Row
            head={dic.about.profile.address.head}
            body={dic.about.profile.address.body}
          />
          <Table.Row
            head={dic.about.profile.capital.head}
            body={dic.about.profile.capital.body}
          />
          <Table.Row
            head={dic.about.profile.establish.head}
            body={dic.about.profile.establish.body}
          />
          <Table.Row
            head={dic.about.profile.directors.head}
            body={dic.about.profile.directors.body}
          />
          <Table.Row
            head={dic.about.profile.customers.head}
            body={dic.about.profile.customers.body}
          />
          <Table.Row
            head={dic.about.profile.salesSection.head}
            body={dic.about.profile.salesSection.body}
          />
          <Table.Row
            head={dic.about.profile.offices.head}
            body={dic.about.profile.offices.body}
          />
          <Table.Row
            head={dic.about.profile.affilated.head}
            body={dic.about.profile.affilated.body}
          />
          <Table.Row
            head={dic.about.profile.facilities.head}
            body={dic.about.profile.facilities.body}
          />
          <Table.Row
            head={dic.about.profile.products.head}
            body={dic.about.profile.products.body}
          />
        </Table.Tbody>
      </Table>
    </Section>
  );
};
