import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Heading, InlineText, Layout, Link, Text } from "~/components";
import { colors, fontSizes, spacing } from "~/styles";

const styles = StyleSheet.create({
  layout: {
    display: "block"
  },
  heading: {
    fontSize: fontSizes.text.xlarge,
    marginTop: "0",
    marginBottom: spacing.xsmall
  },
  text: {
    fontSize: fontSizes.text.small
  },
  linkText: {
    color: colors.cobalt,
    ":hover": {
      textDecoration: "underline"
    }
  }
});

export const Success: VFC = () => {
  return (
    <Layout element="div" styles={styles.layout}>
      <Heading element="h3" styles={styles.heading}>
        送信が完了しました。
      </Heading>
      <Text element="p" styles={styles.text}>
        ご入力いただいたメールアドレスに随時返信いたしますので、しばらくお待ちください。
      </Text>
      <Layout>
        <Text element="p" styles={styles.text}>
          <Link href="/">
            <InlineText styles={styles.linkText}>ホームへ戻る</InlineText>
          </Link>
        </Text>
      </Layout>
    </Layout>
  );
};
