import { StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";
import { About, Hero, News, OnlineShop } from "./components";
import { Layout } from "~/components/Layout";

export type HomeProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  home: {}
});

export const Home: FC<HomeProps> & {
  Hero: typeof Hero;
  About: typeof About;
  OnlineShop: typeof OnlineShop;
  News: typeof News;
} = ({ children }) => {
  return (
    <Layout element="div" styles={styles.home}>
      {children}
    </Layout>
  );
};

Home.Hero = Hero;
Home.About = About;
Home.OnlineShop = OnlineShop;
Home.News = News;
