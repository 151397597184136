import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { media } from "~/styles";

export type ImageProps = {
  source: string;
  alt: string;
};

const styles = StyleSheet.create({
  container: {
    marginTop: "-3rem",
    marginRight: "0",
    width: "92%",
    height: "auto",
    display: "block",
    [media.medium]: {
      marginTop: "-4rem",
      width: "76%"
    }
  },
  image: {
    display: "block",
    maxWidth: "100%"
  }
});

export const Image: VFC<ImageProps> = ({ source, alt }) => {
  return (
    <figure className={css(styles.container)}>
      <img src={source} alt={alt} className={css(styles.image)} />
    </figure>
  );
};
