import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import dayjs from "dayjs";
import { PageController } from "../PageController";
import { Link, NavigationList, Section, Text } from "~/components";
import { Blog as Post } from "~/lib/server/microcms";
import { colors, fontSizes, media, spacing, variables } from "~/styles";

export type BlogProps = {
  items: Post[];
  hasPrev: boolean;
  hasNext: boolean;
  page: number;
};

const styles = StyleSheet.create({
  list: {
    margin: "0",
    padding: "0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  item: {
    width: "100%",
    marginBottom: spacing.medium,
    [media.small]: {
      width: `calc((100% - ${spacing.small}) / 2)`,
      marginLeft: spacing.small,
      ":nth-child(2n + 1)": {
        marginLeft: "0"
      }
    },
    [media.large]: {
      width: `calc((100% - (${spacing.small} * 2)) / 3)`,
      marginLeft: spacing.small,
      ":nth-child(2n + 1)": {
        marginLeft: spacing.small
      },
      ":nth-child(3n + 1)": {
        marginLeft: "0"
      }
    }
  },
  link: {
    display: "flex",
    flexDirection: "column",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    transitionProperty: "opacity, box-shadow, transform",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease",
    ":hover": {
      opacity: 0.64
    },
    ":focus": {
      outline: "none"
    },
    ":active": {
      transform: "scale(0.96)"
    },
    ":focus-visible": {
      boxShadow: variables.focusRing
    }
  },
  imageWrapper: {
    width: "100%",
    paddingTop: "52.5%",
    position: "relative",
    marginTop: "0",
    marginBottom: spacing.xsmall
  },
  image: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    objectRepeat: "no-repeat"
  },
  title: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: colors.shadowDark,
    paddingBottom: spacing.xxsmall,
    marginTop: "0",
    marginBottom: "0"
  },
  date: {
    color: colors.gray,
    margin: "0",
    paddingTop: spacing.xxsmall
  }
});

export const Blog: VFC<BlogProps> = ({ items, hasPrev, hasNext, page }) => {
  return (
    <Section withSpacing>
      <Section.Transformed>
        <NavigationList styles={styles.list}>
          {items.map((item) => (
            <NavigationList.Item key={item.id} styles={styles.item}>
              <Link
                href={`/blog/article/${item.id}`}
                label={item.title}
                styles={styles.link}
              >
                <figure className={css(styles.imageWrapper)}>
                  <img
                    src={
                      item.mainImage
                        ? `${item.mainImage.url}?w=600`
                        : "/images/blog/noimage.jpg"
                    }
                    alt={item.title}
                    className={css(styles.image)}
                  />
                </figure>
                <Text element="div" styles={styles.title}>
                  {item.title}
                </Text>
                <Text element="div" styles={styles.date}>
                  <time dateTime={item.publishedAt}>
                    {dayjs(item.publishedAt).format("YYYY年M月D日")}
                  </time>
                </Text>
              </Link>
            </NavigationList.Item>
          ))}
        </NavigationList>
        <PageController
          category="blog"
          hasPrev={hasPrev}
          hasNext={hasNext}
          page={page}
        />
      </Section.Transformed>
    </Section>
  );
};
