import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Button, Layout } from "~/components";
import { useTranslation } from "~/hooks";
import { colors, spacing } from "~/styles";

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end"
  }
});

export type SubmitProps = {
  submitting: boolean;
};

export const Submit: VFC<SubmitProps> = ({ submitting }) => {
  const { dic } = useTranslation();
  return (
    <Layout element="div" styles={styles.container}>
      <Button
        disabled={submitting}
        type="submit"
        styles={{
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: colors.gray,
          borderRadius: "9999px",
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          paddingTop: spacing.xsmall,
          paddingBottom: spacing.xsmall,
          fontSize: "16px",
          ":disabled": {
            color: colors.gray,
            opacity: 0.64,
            cursor: "not-allowed"
          }
        }}
      >
        {dic.contact.send}
      </Button>
    </Layout>
  );
};
