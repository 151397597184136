import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Form, Info } from "./components";
import { Layout } from "~/components";

export type ContactProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  contact: {}
});

export const Contact: FC<ContactProps> & {
  Form: typeof Form;
  Info: typeof Info;
} = ({ children }) => {
  return (
    <Layout element="div" styles={styles.contact}>
      {children}
    </Layout>
  );
};

Contact.Form = Form;
Contact.Info = Info;
