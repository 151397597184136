import { useState, VFC } from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form/dist/index.ie11";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import axios from "axios";
import {
  Form as FormComponents,
  Input,
  Select,
  Submit,
  Textarea,
  Errorbox,
  Success
} from "./components";
import { Section } from "~/components";
import { maxWidth } from "~/styles";
import { GOOGLE_RECAPTCHA_KEY } from "~/lib/client/values";
import { useTranslation } from "~/hooks";

export type ContactFields = {
  email: string;
  body: string;
};

export const Form: VFC = () => {
  const { dic } = useTranslation();
  const [googleReCAPTCHAToken, setGoogleReCAPTCHAToken] = useState<
    string | null
  >(null);

  const categories = [dic.contact.aboutProduct, dic.contact.aboutRecruit];
  type ContactCategories = typeof categories[number];
  const [category, setCategory] = useState<ContactCategories>(categories[0]);
  const handleChange = (value: string) => setCategory(value);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const schema = z.object({
    email: z
      .string()
      .email({
        message: "メールアドレスの形式が間違っています。"
      })
      .nonempty({
        message: "このフィールドは入力必須です。"
      }),
    body: z
      .string()
      .max(2000, {
        message: "お問い合わせ内容は2000文字以内でご入力ください。"
      })
      .nonempty({
        message: "このフィールドは入力必須です。"
      })
  });

  const methods = useForm<ContactFields>({
    resolver: zodResolver(schema)
  });

  const handleVerify = (token: string) => {
    setGoogleReCAPTCHAToken(token);
  };

  const submitHandler: SubmitHandler<ContactFields> = async (values) => {
    if (!googleReCAPTCHAToken || !category) {
      return;
    }

    if (submitting) {
      return;
    }

    setSubmitting(true);
    const response = await axios.post<{
      success: boolean;
      error?: {
        code: number;
        message: string;
      };
    }>("/api/contact", {
      ...values,
      googleReCAPTCHAToken: googleReCAPTCHAToken,
      category: category
    });
    if (response.data.success) {
      setSubmitting(false);
      setError(null);
      setCompleted(true);
    } else if (response.data.error) {
      setSubmitting(false);
      setError(response.data.error.message);
    }
  };

  return (
    <Section withSpacing>
      <Section.Transformed maxWidth={maxWidth.medium}>
        {!completed ? (
          <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
            <FormProvider {...methods}>
              <FormComponents method="POST" onSubmit={submitHandler}>
                {error && <Errorbox text={error} />}
                <Select
                  options={categories}
                  onChange={handleChange}
                  labelText={dic.contact.type}
                />
                <Input
                  type="email"
                  id="contact_email"
                  name="email"
                  labelText={dic.contact.email}
                />
                <Textarea
                  id="contact_body"
                  name="body"
                  labelText={dic.contact.body}
                  rows={4}
                  maxLength={2000}
                  placeholder={dic.contact.maxString}
                />
                <GoogleReCaptcha onVerify={handleVerify} />
                <Submit submitting={submitting} />
              </FormComponents>
            </FormProvider>
          </GoogleReCaptchaProvider>
        ) : (
          <Success />
        )}
      </Section.Transformed>
    </Section>
  );
};
