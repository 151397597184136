import { VFC } from "react";
import { css, StyleSheet } from "aphrodite";
import Image from "next/image";
import { SectionTitle } from "../SectionTitle";
import { MoreLink } from "../MoreLink";
import { Section, Layout, Text } from "~/components";
import { useTranslation } from "~/hooks/useTranslation";

import {
  colors,
  fontFamily,
  fontSizes,
  leading,
  media,
  spacing,
  tracking
} from "~/styles";

const styles = StyleSheet.create({
  sideText: {
    width: "100%",
    textAlign: "center",
    fontFamily: fontFamily.serif,
    fontSize: fontSizes.text.xlarge,
    fontWeight: 400,
    color: colors.black,
    lineHeight: leading.loose,
    letterSpacing: tracking.wider,
    marginTop: "0",
    [media.medium]: {
      textAlign: "left"
    }
  },
  container: {
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    flexDirection: "column",
    [media.medium]: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  },
  imageWrapper: {
    marginLeft: spacing.xlarge,
    paddingRight: spacing.large,
    alignItems: "flex-end"
  },
  image: {
    opacity: 0.86,
    transform: "rotate(-4deg)"
  },
  summary: {
    maxWidth: "100%",
    textAlign: "left",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    lineHeight: leading.loose,
    whiteSpace: "pre-wrap",
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    [media.medium]: {
      maxWidth: "26em"
    }
  }
});

export const About: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Section withSpacing>
      <SectionTitle labelText="About" headingText={dic.navigation.about} />
      <Text element="p" styles={styles.sideText}>
        {dic.descriptions.mottoTop}
        <br />
        {dic.descriptions.mottoBottom}
      </Text>
      <Layout element="div" styles={styles.container}>
        <Layout element="div" styles={styles.imageWrapper}>
          <Image
            src="/images/bimizeitaku_black.png"
            width={200}
            height={200}
            className={css(styles.image)}
            layout="fixed"
          />
        </Layout>
        <Text element="p" styles={styles.summary}>
          {dic.descriptions.aboutSummay}
        </Text>
      </Layout>
      <MoreLink href="/about" />
    </Section>
  );
};
