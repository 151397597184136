import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import dayjs from "dayjs";
import { Icon, Layout, Text } from "~/components";
import { colors, fontFamily, fontSizes, spacing } from "~/styles";

export type DetailsProps = {
  dateString: string;
  bodyHtml: string;
};

const styles = StyleSheet.create({
  details: {},
  dateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: spacing.large
  },
  dateIcon: {
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.salmon,
    marginRight: spacing.xxsmall
  },
  dateText: {
    margin: "0",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack
  },
  body: {
    color: colors.almostBlack,
    fontSize: fontSizes.text.medium,
    fontWeight: 400,
    fontFamily: fontFamily.sans
  }
});

export const Details: VFC<DetailsProps> = ({ dateString, bodyHtml }) => {
  return (
    <Layout element="div" styles={styles.details}>
      <Layout element="div" styles={styles.dateContainer}>
        <Icon source={faCalendar} styles={styles.dateIcon} />
        <Text element="span" styles={styles.dateText}>
          <time dateTime={dateString}>
            {dayjs(dateString).format("YYYY年M月D日")}
          </time>
        </Text>
      </Layout>
      <Layout element="div" styles={styles.body}>
        <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
      </Layout>
    </Layout>
  );
};
