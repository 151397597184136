import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Layout, Section, Text } from "~/components";
import { useTranslation } from "~/hooks";
import { fontSizes, leading, media, shadow, spacing } from "~/styles";

const styles = StyleSheet.create({
  container: {
    [media.medium]: {
      flexDirection: "row"
    }
  },
  imageContainer: {
    width: "100%",
    maxWidth: "100%",
    flex: "1 1 auto",
    [media.medium]: {
      flex: "0 0 auto",
      maxWidth: "21em"
    }
  },
  imageWrapper: {
    width: "100%",
    maxWidth: "21em",
    margin: "0 auto",
    boxShadow: shadow.xlarge,
    [media.medium]: {
      paddingTop: "100%",
      borderRadius: "50%",
      overflow: "hidden"
    }
  },
  image: {
    display: "block",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    objectFit: "cover",
    [media.medium]: {
      position: "absolute",
      top: "0",
      left: "0"
    }
  },
  story: {
    textAlign: "left",
    whiteSpace: "pre-wrap",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    lineHeight: leading.loose,
    [media.medium]: {
      textAlign: "left",
      marginTop: "0",
      marginBottom: "0",
      marginLeft: spacing.medium,
      maxWidth: "32em"
    }
  }
});

export const Story: VFC = () => {
  const { dic } = useTranslation();
  return (
    <Section withSpacing>
      <Layout element="div" styles={styles.container}>
        <Layout element="div" styles={styles.imageContainer}>
          <Layout element="div" styles={styles.imageWrapper}>
            <img
              src="/images/about/story.jpg"
              alt="story"
              className={css(styles.image)}
            />
          </Layout>
        </Layout>
        <Text element="p" styles={styles.story}>
          {dic.about.story}
        </Text>
      </Layout>
    </Section>
  );
};
