import { css, StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Tbody } from "./Tbody";
import { Row } from "./Row";
import { fontSizes } from "~/styles";

export type TableProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  table: {
    width: "100%",
    fontSize: fontSizes.text.small,
    borderCollapse: "collapse"
  }
});

export const Table: FC<TableProps> & {
  Tbody: typeof Tbody;
  Row: typeof Row;
} = ({ children }) => {
  return <table className={css(styles.table)}>{children}</table>;
};

Table.Tbody = Tbody;
Table.Row = Row;
