import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Heading } from "~/components";
import { fontFamily, fontSizes, spacing } from "~/styles";

export type SectionTitleProps = {
  headingText: string;
};

const styles = StyleSheet.create({
  heading: {
    fontSize: fontSizes.text.large,
    fontWeight: 400,
    marginTop: "0",
    marginBottom: spacing.small,
    fontFamily: fontFamily.sans
  }
});

export const SectionTitle: VFC<SectionTitleProps> = ({ headingText }) => {
  return (
    <Heading element="h2" styles={styles.heading}>
      {headingText}
    </Heading>
  );
};
