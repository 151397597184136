import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { ActionSelect, Icon, Layout } from "~/components";
import { colors, fontSizes, spacing } from "~/styles";

export type SelectProps = {
  onChange: (value: string) => void;
  options: string[];
  labelText?: string;
};

const styles = StyleSheet.create({
  container: {
    width: "auto",
    marginTop: spacing.small,
    marginBottom: spacing.small,
    ":first-child": {
      marginTop: "0"
    }
  },
  icon: {
    position: "absolute",
    width: "16px",
    height: "16px",
    display: "block",
    top: spacing.xxsmall,
    right: spacing.xxsmall
  },
  label: {
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack,
    display: "block",
    marginTop: "0",
    marginBottom: spacing.xxsmall
  }
});

export const Select: VFC<SelectProps> = ({ onChange, options, labelText }) => {
  return (
    <Layout element="div" styles={styles.container}>
      {labelText && <label className={css(styles.label)}>{labelText}</label>}
      <ActionSelect
        containerStyles={{
          position: "relative",
          border: "0px",
          borderBottomWidth: "2px",
          borderBottomStyle: "dashed",
          borderBottomColor: colors.shadowDark,
          borderRadius: "0"
        }}
        selectStyles={{
          flex: "0 0 auto",
          background: "transparent",
          verticalAlign: "baseline",
          paddingRight: spacing.medium,
          paddingLeft: "0",
          paddingTop: spacing.xxsmall,
          paddingBottom: spacing.xxsmall
        }}
        onChange={onChange}
        renderAfterElement={<Icon source={faAngleDown} styles={styles.icon} />}
      >
        {options.map((option, i) => (
          <ActionSelect.Option key={`${option}[${i}]`} value={option}>
            {option}
          </ActionSelect.Option>
        ))}
      </ActionSelect>
    </Layout>
  );
};
