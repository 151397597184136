import { css, StyleSheet } from "aphrodite";
import { useMemo, VFC } from "react";
import { Heading, Layout, Text } from "~/components";
import { useTranslation } from "~/hooks";
import { Product } from "~/lib/server/microcms";
import {
  colors,
  fontFamily,
  fontSizes,
  media,
  shadow,
  spacing,
  tracking
} from "~/styles";

export type ItemProps = {
  product: Product;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.stoneTint,
    padding: spacing.small,
    boxShadow: shadow.xlarge,
    [media.medium]: {
      flexDirection: "row"
    }
  },
  imageFigure: {
    margin: "0",
    display: "flex",
    alignItems: "center",
    [media.medium]: {
      width: `calc(50% - ${spacing.small} / 2)`,
      flex: "0 0 auto",
      marginRight: spacing.small
    }
  },
  image: {
    display: "block",
    width: "100%",
    maxWidth: "100%"
  },
  details: {
    paddingTop: spacing.small,
    paddingBottom: spacing.small,
    [media.medium]: {
      width: `calc(50% - ${spacing.small} / 2)`,
      paddingTop: spacing.large,
      paddingBottom: spacing.large
    }
  },
  heading: {
    fontSize: fontSizes.title.small,
    fontWeight: 700,
    fontFamily: fontFamily.serif,
    letterSpacing: tracking.wider
  },
  text: {
    whiteSpace: "pre-wrap",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    color: colors.almostBlack
  }
});

export const Item: VFC<ItemProps> = ({ product }) => {
  const { lang } = useTranslation();

  const title = useMemo(() => {
    switch (lang) {
      case "cn":
        return product.cnTitle;
      case "ja":
        return product.jpTitle;
      default:
        return "";
    }
  }, [lang]);

  const description = useMemo(() => {
    switch (lang) {
      case "cn":
        return product.cnDescription;
      case "ja":
        return product.jpDescription;
      default:
        return "";
    }
  }, [lang]);
  return (
    <Layout element="div" styles={styles.container}>
      <figure className={css(styles.imageFigure)}>
        <img
          src={product.image.url}
          alt={title}
          className={css(styles.image)}
        />
      </figure>
      <Layout element="div" styles={styles.details}>
        <Heading element="h3" styles={styles.heading}>
          {title}
        </Heading>
        <Text element="p" styles={styles.text}>
          {description}
        </Text>
      </Layout>
    </Layout>
  );
};
