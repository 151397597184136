import { StyleSheet } from "aphrodite";
import { FC } from "react";
import { Heading, Text } from "~/components";
import { Layout } from "~/components/Layout";
import { colors, fontFamily, fontSizes, media, spacing } from "~/styles";

export type SectionTitleProps = {
  labelText: string;
  headingText: string;
  sans?: boolean;
};

export const SectionTitle: FC<SectionTitleProps> = ({
  labelText,
  headingText,
  sans
}) => {
  const styles = StyleSheet.create({
    label: {
      display: "block",
      textAlign: "center",
      fontFamily: fontFamily.serif,
      fontSize: fontSizes.text.large,
      fontWeight: 700,
      color: colors.black,
      marginBottom: spacing.xxsmall,
      [media.medium]: {
        textAlign: "left"
      }
    },
    heading: {
      textAlign: "center",
      fontFamily: sans ? fontFamily.sans : fontFamily.serif,
      fontSize: sans ? fontSizes.title.small : fontSizes.title.medium,
      fontWeight: 700,
      color: colors.black,
      marginTop: "0px",
      marginBottom: spacing.xsmall,
      [media.medium]: {
        textAlign: "left"
      }
    }
  });
  return (
    <Layout element="div">
      <Text element="span" styles={styles.label}>
        {labelText}
      </Text>
      <Heading element="h2" styles={styles.heading}>
        {headingText}
      </Heading>
    </Layout>
  );
};
