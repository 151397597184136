import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { SectionTitle } from "../SectionTitle";
import { MoreLink } from "../MoreLink";
import { Layout, Section, Text } from "~/components";
import { useTranslation } from "~/hooks/useTranslation";
import { colors, fontSizes, leading, media, shadow, spacing } from "~/styles";
import { useNavigation } from "~/hooks/useNavigation";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    [media.medium]: {
      flexDirection: "row"
    }
  },
  imageWrapper: {
    [media.medium]: {
      width: "40%"
    }
  },
  image: {
    display: "block",
    width: "100%",
    backgroundColor: colors.white,
    borderRadius: "0.5rem",
    overflow: "hidden",
    boxShadow: shadow.xlarge,
    maxWidth: "24rem",
    margin: "0 auto"
  },
  mainContainer: {
    flex: "1 1 auto",
    marginTop: spacing.medium,
    [media.medium]: {
      marginTop: "0",
      marginLeft: spacing.medium
    }
  },
  summary: {
    textAlign: "left",
    width: "100%",
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    lineHeight: leading.loose,
    whiteSpace: "pre-wrap",
    margin: "0",
    [media.medium]: {
      textAlign: "left"
    }
  }
});

export const OnlineShop: VFC = () => {
  const { dic } = useTranslation();
  const { onlineShop } = useNavigation();
  return (
    <Section withSpacing>
      <Layout element="div" styles={styles.container}>
        <Layout element="div" styles={styles.imageWrapper}>
          <img
            src="/images/onlineshop_screen.png"
            alt="onlineshop screenshot"
            className={css(styles.image)}
          />
        </Layout>
        <Layout element="div" styles={styles.mainContainer}>
          <SectionTitle
            labelText="Online Shop"
            headingText={dic.onlineshop}
            sans
          />
          <Text element="p" styles={styles.summary}>
            {dic.descriptions.onlineshopSummary}
          </Text>
          <MoreLink href={onlineShop.path} external />
        </Layout>
      </Layout>
    </Section>
  );
};
