import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { SectionTitle } from "../SectionTitle";
import { MoreLink } from "../MoreLink";
import { Link } from "./components";
import { Layout, NavigationList, Section } from "~/components";
import { useTranslation } from "~/hooks/useTranslation";
import { colors, media, spacing } from "~/styles";
import { News as Posts } from "~/lib/server/microcms";

export type NewsProps = {
  news: Posts[];
};

const styles = StyleSheet.create({
  container: {
    [media.medium]: {
      flexDirection: "row"
    }
  },
  titleWrapper: {
    flex: "0 0 auto",
    [media.medium]: {
      flexBasis: "14em"
    },
    [media.xlarge]: {
      flexBasis: "18em"
    }
  },
  list: {
    flex: "1 1 auto",
    [media.medium]: {
      marginLeft: spacing.medium
    }
  },
  item: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: colors.shadowDark,
    ":first-child": {
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: colors.shadowDark
    }
  }
});

export const News: VFC<NewsProps> = ({ news }) => {
  const { dic } = useTranslation();
  return (
    <Section withSpacing>
      <Layout element="div" styles={styles.container}>
        <Layout element="div" styles={styles.titleWrapper}>
          <SectionTitle
            labelText="News"
            headingText={dic.navigation.news}
            sans
          />
        </Layout>
        <NavigationList styles={styles.list}>
          {news.map((item) => (
            <NavigationList.Item key={item.id} styles={styles.item}>
              <Link item={item} />
            </NavigationList.Item>
          ))}
        </NavigationList>
      </Layout>
      <MoreLink href="/news" />
    </Section>
  );
};
