import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Layout, Section } from "~/components";
import { spacing } from "~/styles";

export type ListProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  list: {
    ":nth-of-type(1n) > *": {
      marginBottom: spacing.large
    }
  }
});

export const List: FC<ListProps> = ({ children }) => {
  return (
    <Section withSpacing>
      <Layout element="div" styles={styles.list}>
        {children}
      </Layout>
    </Section>
  );
};
