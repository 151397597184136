import { FC, ReactNode } from "react";
import { Section as SectionComponent } from "~/components";
import { maxWidth } from "~/styles";

export type SectionProps = {
  children?: ReactNode;
};

export const Section: FC<SectionProps> = ({ children }) => {
  return (
    <SectionComponent withSpacing>
      <SectionComponent.Transformed maxWidth={maxWidth.medium}>
        {children}
      </SectionComponent.Transformed>
    </SectionComponent>
  );
};
