import { FC, ReactNode } from "react";
import { Item, List } from "./components";
import { Layout } from "~/components";

export type ProductsProps = {
  children?: ReactNode;
};

export const Products: FC<ProductsProps> & {
  List: typeof List;
  Item: typeof Item;
} = ({ children }) => {
  return <Layout element="div">{children}</Layout>;
};

Products.List = List;
Products.Item = Item;
