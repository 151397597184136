import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import dayjs from "dayjs";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { PageController } from "../PageController";
import {
  Icon,
  Layout,
  Link,
  NavigationList,
  Section,
  Text
} from "~/components";
import { News as Post } from "~/lib/server/microcms";
import { colors, fontSizes, media, spacing, variables } from "~/styles";

export type NewsProps = {
  items: Post[];
  hasPrev: boolean;
  hasNext: boolean;
  page: number;
};

const styles = StyleSheet.create({
  list: {
    margin: "0",
    padding: "0"
  },
  item: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: colors.shadowDark
  },
  link: {
    display: "flex",
    flexDirection: "column",
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.xsmall,
    color: colors.black,
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    transitionProperty: "opacity, box-shadow",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease",
    ":hover": {
      color: colors.black,
      opacity: 0.64
    },
    ":focus": {
      outline: "none"
    },
    ":focus-visible": {
      boxShadow: variables.focusRing
    },
    [media.medium]: {
      flexDirection: "row"
    }
  },
  date: {
    color: colors.gray,
    margin: "0"
  },
  details: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing.xsmall,
    [media.medium]: {
      marginTop: "0",
      marginLeft: spacing.xsmall,
      justifyContent: "flex-end",
      flex: "1 1 auto"
    }
  },
  title: {
    flex: "1 1 auto"
  },
  icon: {
    width: "1em",
    height: "1em",
    marginLeft: spacing.xxsmall,
    marginRight: spacing.xxsmall
  }
});

export const News: VFC<NewsProps> = ({ items, hasPrev, hasNext, page }) => {
  return (
    <Section withSpacing>
      <Section.Transformed>
        <NavigationList styles={styles.list}>
          {items.map((item) => (
            <NavigationList.Item key={item.id} styles={styles.item}>
              <Link
                href={`/news/article/${item.id}`}
                label={item.title}
                styles={styles.link}
              >
                <Text element="span" styles={styles.date}>
                  <time dateTime={item.publishedAt}>
                    {dayjs(item.publishedAt).format("YYYY年M月D日")}
                  </time>
                </Text>
                <Layout element="div" styles={styles.details}>
                  <Layout element="div" styles={styles.title}>
                    {item.title}
                  </Layout>
                  <Icon source={faAngleRight} styles={styles.icon} />
                </Layout>
              </Link>
            </NavigationList.Item>
          ))}
        </NavigationList>
        <PageController
          category="news"
          hasPrev={hasPrev}
          hasNext={hasNext}
          page={page}
        />
      </Section.Transformed>
    </Section>
  );
};
