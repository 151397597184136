import { FC, ReactNode } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form/dist/index.ie11";

export type FormProps = {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  onSubmit: SubmitHandler<any>;
  children?: ReactNode;
};

export const Form: FC<FormProps> = ({
  method = "POST",
  onSubmit,
  children
}) => {
  const { handleSubmit } = useFormContext();
  return (
    <form method={method} onSubmit={handleSubmit(onSubmit)}>
      {children}
    </form>
  );
};
